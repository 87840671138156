import React from "react";
import '../../common/styles/main.css';
import addon_icon_alt from './addon_icon_alt.jpeg';
import '../AddonDetailPage.css'

const PageTitle = ({ addon_name, addon_desc, addon_img }) => {
    const imgURL = addon_img ? addon_img : addon_icon_alt;
    return (
        <header>
            <img src={imgURL} alt="null" />
            <div>
                <div id="main">
                    <h1>{addon_name ? addon_name : '-'}</h1>
                    <h2>{addon_desc ? addon_desc : 'N/A'}</h2>
                </div>
                <div id="sub">
                    <a href="#download_button">다운로드</a>
                    <a href={`http://docs.mows.kr/${addon_name}`}>설명서</a>
                </div>
            </div>
        </header>
    )
}

export default PageTitle;