import React from 'react';
import '../AddonsPage.css';
import noImage from '../../../assets/image/MOWS_no_image.svg';
import failToloadImage from '../../../assets/image/MOWS_image_load_fail.svg';
import { Link } from 'react-router-dom';
import { ip } from '../../../api';

const AddonButton = ({ addon, t }) => {
    const imageUrl = addon.addon_icon ? `http://${ip}${addon.addon_icon}` : noImage;
    console.log('애드온 아이콘 주소' + addon.addon_icon)
    const handleImageError = (e) => {
        e.target.src = failToloadImage;
    }
    const isNewAddon = (new Date() - new Date(addon.latest_version_date)) / (1000 * 60 * 60 * 24) < 7;

    return (
        <Link key={addon.code_name} className="addon-button" to={`/addons/${addon.code_name}`} title={t(`addon_name-${addon.code_name}`) + ' 다운로드'}>
            <div id='main'>
                <img src={imageUrl} onError={handleImageError} alt='이미지 로딩 실패' />
                {isNewAddon && <div className="new-addon-dot" />} {/* 빨간색 점 표시 */}
                <h3>{t(`addon_name-${addon.code_name}`)}</h3>
                <h4>{ }</h4>
            </div>
            <div id='meta'>
                <h5><span translate='no' className="material-symbols-outlined">account_circle</span>{addon.developer}</h5>
                <h5><span translate='no' className="material-symbols-outlined">download</span>{addon.download_count}</h5>
                <h5><span translate='no' className="material-symbols-outlined">update</span>{new Date(addon.latest_version_date).toLocaleDateString()}</h5>
            </div>
        </Link>
    );
};

// AddonButton.propTypes = {
//     image: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     version: PropTypes.string.isRequired,
//     author: PropTypes.string.isRequired,
//     downloads: PropTypes.number.isRequired,
//     updatedAt: PropTypes.string.isRequired,
// };

export default AddonButton;
